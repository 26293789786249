<template>
  <div id="institute_intro">
    <!--  头部banner区域  -->
    <div class="top_banner">
      <img src="@/assets/images/institute/technology_Institute_bg.png" alt="" />
      <div class="top_title">
        <div>
          <h3>晓羊技术研究院</h3>
          <!-- <p>Xiaoyang Technology Research Institute</p> -->
        </div>
      </div>
    </div>
    <!--  内容区域  -->
    <div class="content">
      <!-- 研究院概况  -->
      <div class="intro">
        <div class="title">
          <h3 id="overview">研究院概况</h3>
          <!-- <p class="title_en">Research Institute Overview</p> -->
        </div>
        <div class="intro_content">
          <div class="card">
            <div>
              <i class="iconfont icon-boxes-fill"></i>
              <div>
                <p>
                  晓羊技术研究院是由拥有美国特拉华大学人工智能博士学位的周炜博士领衔、汇聚了近百位研发人员组成的国际专业的技术研发团队。研究院继承了公司前身、全球著名的教育软件公司Blackboard的精英技术成员与技术资源，具备中美欧三地算法中心支持，国内布局多个研发中心，具备国际化专业水准和国内实践研发经验。晓羊技术研究院紧随大语言模型等前沿AI技术的发展趋势，并将其深度融合到自有产品中，为教育领域提供智能、高效的解决方案。通过海量多模块的数据汇集和处理能力、开放平台生态等方面的核心优势，晓羊技术研究院致力于构建云化的数据中台+平台化的支撑管理体系，全面深度整合集团的技术研发、应用生态、产品服务，实现区校融合、业务融合、数据融通、服务融合的四大融合，打造教育信息化领域真正拥有自主知识产权的、数据融通的、全栈的产品生态，覆盖智慧校园全场景，构建包含精准教学、科学评价、超轻量级课堂互动、家校社协同育人、校园安全风险防控、能源管理、AI智能交互屏信息终端等领域的智慧教育全业务生态，解决区校面临的“应用孤岛、信息孤岛、数据孤岛”教育信息化技术痛点，推动智慧教育多场景落地，赋能数智化转型，全面推动教育信息化的深刻变革。
                </p>
              </div>
            </div>
            <img
              src="@/assets/images/institute/technologe_Institute.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- 创新技术 -->
      <div class="specialty">
        <div class="title">
          <h3 id="resource">创新技术</h3>
          <!-- <p class="title_en">Innovative Technology</p> -->
        </div>
        <ul class="specialty_content">
          <li>
            <div class="pic">
              <img
                src="@/assets/images/institute/technologe_Innovative1.png"
                alt=""
              />
            </div>
            <div class="text">
              <h4 class="color-primary">—</h4>
              <p>
                基于启发式搜索、基因算法、隐马尔科夫模型等核心算法的新高考排选课系统。
              </p>
            </div>
          </li>

          <li>
            <div class="pic">
              <img
                src="@/assets/images/institute/technologe_Innovative2.png"
                alt=""
              />
            </div>
            <div class="text">
              <h4 class="color-primary">—</h4>
              <p>专门针对教育行业设计的数据中台。</p>
            </div>
          </li>
          <li>
            <div class="pic">
              <img
                src="@/assets/images/institute/technologe_Innovative3.png"
                alt=""
              />
            </div>
            <div class="text">
              <h4 class="color-primary">—</h4>
              <p>
                聚焦NB-IoT（窄带物联网技术）在学校能源管理领域的创新应用，搭载具有自主知识产权的稳定高效的物联管控平台和物联能耗平台，在智能硬件、中间件、控制模型和系统集成方面取得全面突破。
              </p>
            </div>
          </li>
          <li>
            <div class="pic">
              <img
                src="@/assets/images/institute/technologe_Innovative4.jpg"
                alt=""
              />
            </div>
            <div class="text">
              <h4 class="color-primary">—</h4>
              <p>
                搭载自研“晓慧AIoT平台+XMUI云屏系统”打造晓慧云屏，以“云+端”的AI云技术为各行业单位提供AI交互云屏。
              </p>
            </div>
          </li>
          <li>
            <div class="pic">
              <img
                src="@/assets/images/institute/technologe_Innovative5.jpg"
                alt=""
              />
            </div>
            <div class="text">
              <h4 class="color-primary">—</h4>
              <p>
                融合超声波技术、红外技术、无线组网技术、笔记矢量化技术等多种高新技术推出智笔课堂互动系统，涵盖了多种课堂互动形式的超轻量级课堂互动产品，打造深度课堂。
              </p>
            </div>
          </li>
          <li>
            <div class="pic">
              <img
                src="@/assets/images/institute/technologe_Innovative6.jpg"
                alt=""
              />
            </div>
            <div class="text">
              <h4 class="color-primary">—</h4>
              <p>
                【大数据+云计算+AI+IoT+移动应用】多种技术手段组合，依托自主研发并已获得发明专利技术的精准管理一体机，推出智校安·校园安全综合管理平台，整体打造和提升校园安全风险防控体系和能力。
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Institute',
  data() {
    return {}
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
%intro {
  .bg-primary {
    background-color: $--color-primary !important;
  }
  .top_banner {
    position: relative;
    margin-bottom: 50px;
    img {
      width: 100%;
    }
    .top_title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 42px;
      background-color: rgba(0, 0, 0, 0.5);
      color: $--color-white;
      text-align: center;
      h3 {
        margin: 0;
        font-size: 56px;
        @include px2vw('font-size', 56);
      }
      p {
        margin-bottom: 0;
        font-size: 32px;
        @include px2vw('font-size', 32);
      }
    }
  }
  .content {
    width: 90%;
    max-width: 1320px;
    margin: 0 auto;
    // 通用头部样式
    .title {
      text-align: center;
      color: #333;
      h3 {
        line-height: 36px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    // 研究院简介
    .intro {
      &_content {
        margin-top: 60px;
        & > p {
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
          margin-bottom: 42px;
        }
        .card {
          width: 100%;
          box-sizing: border-box;
          padding: 60px 100px;
          box-shadow: 0px 10px 40px 0px rgba(0, 2, 46, 0.08);
          border-radius: 24px;
          margin-bottom: 50px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          & > div {
            width: 60%;
            i.iconfont {
              display: block;
              width: 46px;
              height: 46px;
              background-color: $--color-primary;
              color: $--color-white;
              font-size: 34px;
              text-align: center;
              line-height: 46px;
              border-radius: 16px;
              margin-bottom: 36px;
            }
            p {
              line-height: 32px;
              font-size: 18px;
              text-align: justify;
              color: #666;
            }
          }
          img {
            width: 35%;
            margin-top: 80px;
          }
        }
      }
    }
    // 专业优势
    .specialty {
      margin-bottom: 50px;
      &_content {
        margin-top: 60px;
        display: flex;
        // justify-content: space-around;
        height: max-content;
        flex-wrap: wrap;
        li {
          width: 30%;
          box-shadow: 0px 2px 24px 0px rgba(184, 184, 184, 0.5);
          margin-bottom: 50px;
          margin-right: 5%;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .pic {
            display: flex;
            align-items: center;
            img {
              width: 100%;
              object-fit: contain;
            }
            // min-height: 280px;
          }
          .text {
            padding: 16px 33px;
            h4 {
              font-size: 20px;
            }
            p {
              font-size: 14px;
              line-height: 28px;
              color: #666;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1000px) {
      .intro {
        &_content {
          .card {
            display: block;
            & > div {
              width: 100%;
              i {
                margin: 0 auto;
              }
              ul {
                justify-content: center;
              }
            }
            img {
              margin-top: 40px;
              width: 100%;
            }
          }
        }
      }
      .specialty {
        &_content {
          display: block;
          li {
            width: 100%;
            margin-bottom: 60px;
            div {
              h4 {
                text-align: center;
                font-size: 32px;
              }
              p {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

.view-pc > #institute_intro {
  @extend %intro;
  .top_banner {
    margin-top: 125px;
  }
  .specialty {
    width: 90%;
    margin: 0 auto;
    .pic {
      height: 280px;
      img {
        height: 100%;
        object-fit: cover !important;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .content {
      .intro {
        .card {
          padding: 40px;
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          img {
            width: 300px;
            display: block;
            margin: 20px auto;
          }
        }
      }
    }
    .specialty {
      width: 90%;
      margin: 0 auto;
      .pic {
        img {
          position: unset !important;
          height: 100% !important;
          width: 100% !important;
          object-position: unset !important;
        }
      }
    }
    .core {
      width: 90%;
      margin: 0 auto;
    }
    .edu_type {
      width: 90%;
      margin: 0 auto;
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .specialty {
      &_content {
        li {
          div {
            padding: 16px 25px !important;
          }
        }
      }
    }
  }
}

.view-mobile > #institute_intro {
  @extend %intro;
  margin-top: 44px;
  .top_banner {
    margin-bottom: 30px;
    display: flex;

    .top_title {
      width: 100%;
      height: 100%;
      padding: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px 40px;
        h3 {
          font-size: 20px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .content {
    .title_en {
      font-size: 14px !important;
      margin-top: 10px;
    }
    h3,
    h4 {
      line-height: 30px !important;
    }
    h4 {
      font-size: 20px !important;
    }
    padding: 0 10px;
    box-sizing: border-box;
    .title {
      text-align: center;
      color: #333;
      h3 {
        line-height: 36px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 18px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .intro {
      &_content {
        margin-top: 20px;
        .card {
          padding: 20px;
          margin-bottom: 30px;
          > div {
            p {
              font-size: 14px;
              line-height: 28px;
              margin-bottom: 0;
            }
          }
          img {
            margin-top: 30px;
          }
          i {
            width: 46px !important;
            height: 46px !important;
            line-height: 46px !important;
          }
        }
      }
    }
    .specialty {
      margin-bottom: 0;
    }
    .specialty_content {
      margin-top: 30px;
      .pic {
        min-height: unset;
      }
      h4 {
        font-size: 20px !important;
        margin: 0;
      }
      li {
        margin-bottom: 30px;
      }
    }
    @media screen and (max-width: 500px) {
    }
  }
}
</style>
